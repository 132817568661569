<template>
  <Nabvar />
  <Slider />
  <!--LINK FORMULARIOS-->


  <section>
    <div class="grid md:grid-cols-4 z-20 relative -mt-20">
      <div class="bg-otherblue p-8 border-2 border-white">
        <p class="text-white text-justify text-lg p-3">Citas en Línea</p>
        <p class="text-white text-left text-md p-2">
          Solicite su cita!, será programada via correo electrónico.
        </p>
        <button
          class="border-2 rounded-md py-1 px-5 text-left text-white hover:bg-white hover:text-otherblue"
           @click="$router.push('CitasEnLinea')"
        >
          Solicitar cita
        </button>
      </div>
      <div class="bg-graynefro p-8 border-2 border-white">
        <p class="text-white text-justify text-lg p-0.5">Contáctenos</p>
        <p class="text-white text-left text-md p-2">
          Déjenos un mensaje, con gusto será atendido.
        </p>
        <button
          class="border-2 mt-5 rounded-md py-1 px-5 text-left text-white hover:bg-white hover:text-otherblue"
          @click="this.$router.push('Contactenos')"
        >
          Enviar Un Mensaje
        </button>
      </div>
      <div class="bg-otherblue p-8 border-2 border-white">
        <p class="text-white text-justify text-lg">PQR</p>
        <p class="text-white text-left text-md p-2">
          Su opinión es muy impórtate para nosotros.
        </p>
        <button
          class="border-2 mt-5 rounded-md py-1 px-5 text-left text-white hover:bg-white hover:text-otherblue"
          @click="this.$router.push('Pqr')"
        >
          Enviar PQR
        </button>
      </div>
      <div class="bg-graynefro p-8 border-2 border-white">
        <p class="text-white text-justify text-lg">Trabaja con Nosotros</p>
        <p class="text-white text-left text-md p-2">
          Déjenos su hoja de vida para evaluarla con nuestros vacantes.
        </p>
        <button
          class="border-2 mt-5 rounded-md py-1 px-5 text-left text-white hover:bg-white hover:text-otherblue"
          @click="this.$router.push('TrabajaConNosotros')"
        >
          Enviar Hoja de Vida
        </button>
      </div>
    </div>
  </section>
  <!--FIN LINK FORMULARIOS-->
    <!--MISIÓN Y VISIÓN-->

    <Vision  />

  <!--FIN MISIÓN Y VISIÓN-->
  
  <div class="items-center flex flex-wrap mt-12 ">
    <div class="w-full md:w-4/12 ml-auto mr-auto px-4 ">
      <iframe  width="560" height="315" :src="videoUrl" frameborder="0" allow="autoplay: encryted-media" allowfullscreen></iframe>
    </div>
    
    <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
      <div class="md:pr-12">
              <div
          class="z-20 relative p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white mt-8"
        >
          <img src="../assets/LOGOCDN(2).png" />
        </div>
        <h3 class="text-3xl w-full font-semibold z-20 relative">
          Conoce Nuestra Familia En Redes Sociales.
        </h3>
        <p class="mt-4 text-lg leading-relaxed text-blueGray-500"></p>
      </div>
    </div>
  </div>

 <!-------------------------------- CUADRO DE LOS HORARIOS Y SERVICIOS ------------------------------------>

  <div class="items-center flex flex-wrap mt-12 ">
    <div class=" ml-auto mr-auto px-1 ">
      <img widht="156px" src="../assets/Horario.png" />
    </div>
  </div>




  <!--PLANES EJECUTIVOS 2-->
  
  <!-- ====== Pricing Section Start -->
  <Planes/>
  <Empresas />
  <!-- ====== Pricing Section End -->
</template>

<script>
import Slider from "../components/Slider";
import Vision from "../components/MisionVision";
import Planes from "../components/Planes";
import Empresas from "../components/EmpresasUros";
// import { ref } from "@vue/reactivity";
// import { global } from "../Global";
// import { onMounted } from "vue";
// import axios from "axios";
export default {
  components: {
    Slider,
    Vision,
    Planes,
    Empresas
  },
  setup() {
    
    const videoUrl ="https://www.youtube.com/embed/CuHsmFZCzeo";
    // onMounted(async () => {
    //   planes.value = await axios.get(global.url + "Planes").then((res) => {
    //     console.log(res.data);
    //     return res.data;
    //   });
    //   // console.log(planes);
    // });
    // const planes = ref({});
    return {videoUrl  };
  },
};
</script>
