<template>
  <div class="bg-otherblue py-2 z-20 relative">
    <a class="-mt-1" href="https://www.facebook.com/casadelninomonteria/"
      ><i class="fab fa-facebook-square text-white text-3xl ml-4"></i
    ></a>
    <a class="-mt-1" href="https://www.instagram.com/casadelninomonteria/"
      ><i class="fab fa-instagram-square text-white text-3xl ml-4"></i
    ></a>
    <a class="-mt-1" href="https://twitter.com/casadelninomon"
      ><i class="fab fa-twitter-square text-white text-3xl ml-4"></i
    ></a>
    <a
      class="-mt-1"
      href="https://www.youtube.com/@casadelninomonteria/featured"
      ><i class="fab fa-youtube-square text-white text-3xl ml-4"></i
    ></a>
  </div>

  <footer class="text-gray-600 body-font bg-gray-200 z-20 relative">
    <div
      class="container px-5 pt-5 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col"
    >
      <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <a
          class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
        >
          <div
            class="z-20 text-center inline-flex items-center justify-center w-24 mb-6 rounded-full"
          >
            <img src="../assets/LOGOCDN(2).png" />
          </div>

          <!-- <span class="ml-3 text-xl text-black">DEV</span> -->
        </a>
        <!-- <p class="text-sm text-black">
         "Servimos con el Alma"
        </p> -->
      </div>
      <div
        class="flex-grow flex flex-wrap md:pl-20 md:mt-0 md:text-left text-center"
      >
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 class="title-font font-semibold text-black text-xs mb-3">
            Nuestra Clínica
          </h2>
          <nav class="list-none mb-10">
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/Acerca-De-Nosotros"
              >
                Acerca de Nosotros</router-link
              >
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/Copasst"
              >
                COPASST
              </router-link>
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/En-La-Region"
              >
                En la Región
              </router-link>
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/Servicios"
              >
                Servicios</router-link
              >
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 class="title-font font-semibold text-black text-xs mb-3">SIAU</h2>
          <nav class="list-none mb-10">
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/Deberes-y-Derechos"
              >
                Deberes y Derechos
              </router-link>
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/PQR"
              >
                PQR
              </router-link>
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/CitasEnLinea"
              >
                Citas en Línea
              </router-link>
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/Asociacion-De-Usuarios"
              >
                Asociación de Usuarios
              </router-link>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 class="title-font font-semibold text-black text-xs mb-3">
            Chequeos Médicos
          </h2>
          <nav class="list-none mb-10">
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/PlanPlus"
              >
                Plan Plus
              </router-link>
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/PlanElite"
              >
                Plan Elite
              </router-link>
            </li>
            <li>
              <router-link
                class="text-black hover:text-otherblue text-xs"
                to="/PlanGold"
              >
                Plan Gold
              </router-link>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 class="title-font font-semibold text-black text-xs mb-3">
            Enlaces de interés
          </h2>
          <nav class="list-none mb-10">
            <li>
            <router-link
              class="text-black hover:text-otherblue text-xs"
              to="/Sedes"
            >
              Nuestras sedes
            </router-link>
            </li>
            <li>
            <router-link
              class="text-black hover:text-otherblue text-xs"
              to="/Estados-Financieros"
            >
              Estados Financieros
            </router-link>
            </li>
          </nav>
        </div>
      </div>
    </div>
    <div class="bg-gray-200">
      <div
        class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row"
      >
        <p class="text-sm text-center md:text-right">
          &copy;2022
          <a
            href="https://www.itmsas.net"
            class="font-normal text-sm hover:text-gray-400 mb-2"
            >IT Management Zomac s.a.s</a
          >
          . Todos los derechos reservados. | All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
