<template>
  <link
    rel="stylesheet"
    href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"
  />
  <!-- <header class="w-full top-0 z-50 fixed "> -->
  <div class="fixed w-full z-50">
    <nav
      class="md:flex bg-white shadow dark:bg-gray-800 w-full z-50 h-20 link-router-active"
    >
      <div class="container">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="flex items-center justify-between">
            <img
              alt="IT logo"
              class="w-36 md:ml-32 ml-5"
              src="../assets/LOGO(2).png"
              @click="$router.push('/')"
            />

            <!-- Mobile menu button -->
            <div class="md:flex lg:hidden">
              <button
                @click="desplegar()"
                type="button"
                class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                aria-label="toggle menu"
              >
                <svg viewBox="0 0 24 24" class="w-6 h-6 mr-5 mt-5 fill-current">
                  <path
                    fill-rule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
          <div
            class="lg:flex lg:mt-5"
            v-bind="{ block: showMenu, hidden: !showMenu }"
          >
            <ul
              class="bg-white md:px-2 ml-auto md:flex md:space-x-2 absolute md:relative top-full left-0 right-0"
            >
              <li>
                <router-link
                  to="/"
                  class="lg:p-4 py-3 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro"
                >
                  Inicio
                </router-link>
              </li>
              <!-- dropdown -->

              <li class="relative parent">
                <div class="flex">
                  <div
                    @mouseover="mostrar"
                    @mouseout="mostrar"
                    type="button"
                    class="bg-white md:flex m-auto space-x-2 lg:p-4 px-0 border-b-2 border-transparent hover:border-otherblue hover:text-graynefro md:w-full font-sans font-semibold"
                    aria-label="toggle menu"
                  >
                    Nuestra Clínica
                    <svg
                      class="h-5 w-5 flex text-gray-800 mt-1 transform"
                      :class="showSubMenu ? 'rotate-180' : ''"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <div>
                      <div
                        v-bind="{ block: showSubMenu, hidden: !showSubMenu }"
                        class="md:absolute bg-white md:w-48 md:mt-11 md:-ml-40 md:shadow-xl"
                      >
                        <router-link
                          to="/Acerca-De-Nosotros"
                          class="py-4 block font-sans font-semibold border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Acerca de Nosotros
                        </router-link>
                        <router-link
                          to="/Copasst"
                          class="block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >COPASST
                        </router-link>

                        <router-link
                          to="/En-La-Region"
                          class="py-4 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >En la Región</router-link
                        >
                        <!-- Menú estados financieros -->
                        <div
                          @mouseover="showSubMenuEF"
                          @mouseout="showSubMenuEF"
                          class="py-4 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                        >
                          Estados Financieros
                          <div
                            v-bind="{
                              block: showEstadosFinacieros,
                              hidden: !showEstadosFinacieros
                            }"
                            class="md:absolute bg-white md:w-48 md:-mt-9  md:ml-48 md:shadow-xl"
                          >
                            <router-link
                              to="/Estados-Financieros-2019"
                              class="py-4 block font-sans font-semibold border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                              >2019
                            </router-link>
                            <router-link
                              to="/#"
                              class="block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                              >2020
                            </router-link>

                            <router-link
                              to="/#"
                              class="py-4 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                              >2021</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <!-- fin dropdown -->
              <!--Dropdown siau-->

              <li class="relative parent">
                <div class="flex">
                  <div
                    @mouseover="ver"
                    @mouseout="ver"
                    type="button"
                    class="bg-white m-auto md:inline-flex space-x-2 lg:p-4 px-0 border-b-2 border-transparent hover:border-otherblue hover:text-graynefro md:w-full font-sans font-semibold"
                    aria-label="toggle menu"
                  >
                    SIAU
                    <svg
                      class="h-5 w-5 text-gray-800 mt-1 transform"
                      :class="showSubMenul ? 'rotate-180' : ''"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <div>
                      <div
                        v-bind="{ block: showSubMenul, hidden: !showSubMenul }"
                        class="md:absolute bg-white md:w-48 md:mt-11 md:-ml-32 md:shadow-xl"
                      >
                        <router-link
                          to="/Deberes-y-Derechos"
                          class="py-4 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Deberes y derechos
                        </router-link>
                        <router-link
                          to="/Pqr"
                          class="py-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >PQR
                        </router-link>

                        <router-link
                          to="/CitasEnLinea"
                          class="py-3 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Citas en linea</router-link
                        >
                        <router-link
                          to="/Asociacion-De-Usuarios"
                          class="py-3 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Asociación de usuarios</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!--Fin dropdown-->
              <li>
                <router-link
                  to="/Servicios"
                  class="lg:p-4 py-3 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro"
                >
                  <span>Servicios</span>
                </router-link>
              </li>
              <li>
                <router-link
                  to="/Contactenos"
                  class="lg:p-4 py-3 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro"
                >
                  <span>Contáctenos</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <!-- </header> -->
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: {
    array: Array,
  },
  setup() {
    let showSubMenu = ref(false);
    let showMenu = ref(false);
    let showEstadosFinacieros = ref(false);
    function desplegar() {
      showMenu.value = !showMenu.value;
    }
    function mostrar() {
      showSubMenu.value = !showSubMenu.value;
    }
    let showSubMenul = ref(false);
    function ver() {
      showSubMenul.value = !showSubMenul.value;
    }
    let showSubMenuEF = () => {
      showEstadosFinacieros.value = !showEstadosFinacieros.value 
    };

    return {
      desplegar,
      showMenu,
      mostrar,
      showSubMenu,
      showSubMenul,
      ver,
      showSubMenuEF,
      showEstadosFinacieros
    };
  },
};
</script>

<style></style>
